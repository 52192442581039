<template>
  <div
    class="PageLoader"
    @touchmove.prevent
    @wheel.prevent
  >
    <loader />
  </div>
</template>

<script>
import loader from '@/components/global/loader.vue'

export default {
  name: 'PageLoader',
  components: {
    loader
  }
}
</script>

<style lang="scss" scoped>
.PageLoader {
  @at-root {
    & {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: $base-w;
      margin-left: auto;
      margin-right: auto;
      background: #040809 url(#{$img-path}bg_main-top.png) no-repeat center top;
      z-index: map-get($z-index, loader);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>
