<template>
  <div v-if="hasSnsBtn" class="SnsAccount">
    <p class="SnsAccount__Text" v-text="$t('menu.010')" />
    <div class="SnsAccount__Buttons">
      <a
        :href="$t('vars.twitter')"
        target="_blank"
        rel="noopener noreferrer"
        class="SnsAccount__Button -twitter"
      >
        <img src="@images/sns-share/icon_twitter.png" alt="" />
      </a>
      <a
        v-if="!isJaJp"
        :href="$t('vars.facebook')"
        target="_blank"
        rel="noopener noreferrer"
        class="SnsAccount__Button -facebook"
      >
        <img src="@images/sns-share/icon_facebook.png" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
import LocaleMixin from '@/mixins/locale'

export default {
  name: 'sns-account',
  mixins: [LocaleMixin],
  computed: {
    hasSnsBtn () {
      const whiteList = ['ja-jp', 'en-gb', 'en-us']
      return whiteList.some(w => w === this.locale)
    },
    isJaJp () {
      return this.locale === 'ja-jp'
    }
  }
}
</script>
<style lang="scss" scoped>
.SnsAccount {
  @at-root {
    & {
    }
    .SnsAccount__Text {
      margin: 0 20px;
      color: $color-sub;
      text-align: center;
      font-size: $fs-2xlarge;
    }
    .SnsAccount__Buttons {
      display: flex;
      justify-content: center;
      background: url(#{$img-path}sns-account/bg_sns.png) left center repeat-x;
      margin-top: 30px;
    }
    .SnsAccount__Button {
      @include tap-event();
      margin: 0 20px;
      padding: 0;
      background-color: transparent;
    }
  }
}
</style>
