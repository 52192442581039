<template>
  <header
    :class="{'-open':isOpen}"
    class="MainHeader"
  >
    <div class="MainHeader__Container">
      <p class="MainHeader__Catchphrase" v-text="$t('common.header-001')" />
      <div class="MainHeader__Buttons Buttons">
        <button class="Buttons__Menu" @click="clickMenu(isOpen)">
          <img :src="btnMenu" :alt="$t('common.header-004')" />
        </button>
        <h1 class="Buttons__Title">
          <img
            :src="require(`@images/the-header/${locale}/img_logo.png`)"
            :alt="$t('common.header-002')+$t('common.header-003')"
          />
        </h1>
        <button-sound :class="{'-hide': !isShowSoundButton}" />
      </div>
    </div>
    <menus class="MainHeader__Menu" />
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LocaleMixin from '@/mixins/locale'
import Menus from '@/components/the-menu.vue'
import ButtonSound from '@/components/global/button-sound.vue'

export default {
  name: 'MainHeader',
  mixins: [LocaleMixin],
  components: {
    Menus,
    ButtonSound
  },
  computed: {
    ...mapGetters('menu', ['isOpen']),
    btnMenu () {
      return this.isOpen ? require(`@images/the-header/${this.locale}/btn_menu-close.png`) : require(`@images/the-header/${this.locale}/btn_menu-open.png`)
    },
    isShowSoundButton () {
      const excludeList = ['About']
      return excludeList.every((item) => {
        return this.$route.name !== item
      })
    }
  },
  watch: {
    '$route' () {
      if (this.isOpen) {
        this.close()
      }
    }
  },
  methods: {
    ...mapActions('menu', ['open', 'close']),
    clickMenu (state) {
      state ? this.close() : this.open()
    }
  }
}
</script>

<style lang="scss" scoped>
.MainHeader {
  @at-root {
    & {
      position: relative;
      z-index: map-get($z-index, header);
    }
    .MainHeader__Container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 3;
      width: $base-w;
      margin: auto;
      overflow: hidden;
    }
    .MainHeader__Catchphrase {
      background-color: $color-sub;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 32px;
      padding: 5px 10px;
      position: relative;
      z-index: 99;
      color: $color-base;
      font-size: $fs-middle;
      line-height: 1.1;
      text-align: center;
    }
    .MainHeader__Buttons {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      height: 180px;
      position: relative;
      z-index: 99;
      background: url(#{$img-path}the-header/bg_header.png) no-repeat center -2px;
    }
    .MainHeader__Menu {
      position: absolute;
      top: -1477px;
      left: 0;
      bottom: 0;
      z-index: 2;
      transform: translate(0px, -1477px);
      transition: transform 0.4s ease-in-out;
    }

    &.-open {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;

      .MainHeader__Container {
        position: fixed;
      }
      .MainHeader__Menu {
        transform: translate(0px, 1477px);
      }
    }
  }
}

.Buttons {
  @at-root {
    .Buttons__Menu {
      @include tap-event();
      background-color: transparent;
      width: 142px;
      height: 143px;
      margin: 0 0 0 -15px;
      padding: 0;

      img {
        display: block;
      }
    }
    .Buttons__Title {
      margin-top: 14px;
      margin-left: -38px;
    }
    .Buttons__Sound {
      @include tap-event();
      background-color: transparent;
      width: 92px;
      height: 92px;
      margin: 28px 10px 0 0;
      padding: 0;

      &.-hide {
        visibility: hidden;
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
}
</style>
