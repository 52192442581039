export default class Hero {
  constructor (json) {
    try {
      this.map(json)
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }

  map (json) {
    this.id = json.hero_id || ''
    this.seriesId = json.series_id || ''
    this.sort = json.sort || ''
    this.sameHero = {
      id: json['same_hero.id'] || '',
      heroIds: json['same_hero.hero_ids'].split(',').filter(Boolean),
      seriesIds: json['same_hero.series_ids'].split(',').filter(Boolean)
    }
  }

  toJSON () {
    return {
      id: this.id,
      seriesId: this.seriesId.hero_id,
      sort: this.sort,
      sameHero: this.sameHero
    }
  }
}
