<template>
  <div id="app" class="App" :class="[{'-opened' : isOpen}, `-${locale}`]">
    <div class="App__Container">
      <main-header />
      <router-view class="App__Main" />
      <main-footer />
    </div>
    <modal />
    <modal-terms />
    <loader v-if="isLoading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { validateLocale, getLocale } from '@/constants/locale'
import LocaleMixin from '@/mixins/locale'
import MainHeader from '@/components/the-header.vue'
import MainFooter from '@/components/the-footer.vue'
import Modal from '@/components/modal.vue'
import ModalTerms from '@/components/modals/terms.vue'
import Loader from '@/components/the-loader.vue'

export default {
  mixins: [LocaleMixin],
  components: {
    MainHeader,
    MainFooter,
    Modal,
    ModalTerms,
    Loader
  },
  computed: {
    ...mapGetters('term', ['resultOpen']),
    ...mapGetters('loader', ['isLoading']),
    ...mapGetters('menu', ['isOpen', 'menuHeight'])
  },
  watch: {
    '$route.params.lang' (newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        this.$i18n.locale = newVal
      }
    },
    isOpen (newValue) {
      const elem = document.querySelector('.App__Container')
      elem.style.height = newValue ? `${this.menuHeight}px` : ''
    }
  },
  created () {
    const locale = location.pathname.split('/')[1]
    if (!validateLocale(locale)) {
      const localePath = getLocale().value.name
      location.href = `/${localePath}`
      return false
    }
    if (!location.search && /^.*?\/series$/g.test(location.pathname)) {
      location.href = `${location.pathname}?series=101`
    }
    this.$i18n.locale = locale
    this.initLogin()
    this.initTerm()
    this.initVote()
    if (this.resultOpen) {
      this.initResult()
    }
  },
  methods: {
    ...mapActions('login', { initLogin: 'init' }),
    ...mapActions('term', { initTerm: 'init' }),
    ...mapActions('vote', { initVote: 'init' }),
    ...mapActions('result', { initResult: 'init' })
  }
}
</script>

<style lang="scss">
.App {
  @at-root {
    & {
      position: relative;
      width: 100%;
      background: url(#{$img-path}bg_pc.jpg) no-repeat center top;
      background-size: 100vw auto;
      background-attachment: fixed;
      font-family: $ff-lang-jp;

      &.-opened {
        overflow: hidden;
      }
    }
    .App__Container {
      position: relative;
      display: flex;
      flex-direction: column;
      width: $base-w;
      // @NOTE 以降全ての100%計算が狂うので使用禁止
      // min-height: 100vh;
      margin: 0 auto;
      overflow: hidden;
      background-image: url(#{$img-path}bg_main-top.png), url(#{$img-path}bg_main-base.jpg);
      background-repeat: no-repeat, repeat-y;
      background-position: center top, center top;
      background-color: #040809;
    }
    .App__Main {
      flex-grow: 1;
      margin-top: 212px;
    }
  }
}
</style>
