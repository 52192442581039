<template>
  <div
    v-if="isShowTerms"
    class="Modal"
    @touchmove.prevent
    @touchend.prevent
  >
    <iscroll-view
      :options="iScrollView.options"
      :scrollerClass="{ scroller: true }"
      ref="iscroll"
      class="Modal__Wrapper"
    >
      <div class="Modal__Container">
        <button-close class="Modal__ButtonClose" @close="clickCloseBtn" />
        <h2 class="Modal__Title" v-html="$t('voteAgreement.001')" />
        <div class="Modal__Details" v-html="replaceText($t('voteAgreement.003'), { samelist_url: `/${locale}/character` })" />
      </div>
    </iscroll-view>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LocaleMixin from '@/mixins/locale'
import replaceTextMixin from '@/mixins/replace-text'
import ButtonClose from '@/components/modals/close.vue'

export default {
  name: 'Modal',
  mixins: [LocaleMixin, replaceTextMixin],
  components: {
    ButtonClose
  },
  data () {
    return {
      $iscroll: null,
      iScrollView: {
        options: {
          scrollbars: true,
          fadeScrollbars: true,
          shrinkScrollbars: 'scale',
          mouseWheel: true,
          click: true
        }
      }
    }
  },
  computed: {
    ...mapGetters('modal', ['isShowTerms'])
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$iscroll = this.$refs.iscroll
        this.refreshIScroll()
      })
    })
  },
  watch: {
    '$route.path' () {
      if (this.isShowTerms) {
        this.hideTerms()
      }
    }
  },
  methods: {
    ...mapActions('modal', { hideTerms: 'toggleTerms' }),
    clickCloseBtn () {
      this.hideTerms()
    },
    refreshIScroll () {
      if (!this.$iscroll) {
        return
      }
      setTimeout(() => {
        this.$iscroll.refresh()
        this.$iscroll.scrollTo(0, 0, 0)
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.Modal {
  @at-root {
    & {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: map-get($z-index, modal);
      backface-visibility: hidden;
      overflow: hidden;
      background-color: rgba($color-sub, 0.8);
    }
    .Modal__Wrapper {
      touch-action: none;
      width: 100%;
      height: 100%;
    }
    .Modal__Container {
      padding: 20px;
      height: 100%;
      width: $base-w;
      margin: auto;
      position: relative;
    }
    .Modal__Title {
      text-align: center;
      margin: 20px 0 10px;
      padding: 0 110px;
      line-height: 1.4;
      font-size: $fs-3xlarge;
    }
    .Modal__Subtitle {
      text-align: center;
      margin-bottom: 30px;
      padding: 0 110px;
    }
    .Modal__Details {
      padding: 40px 0;
    }
    .Modal__ButtonClose {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 1;
    }

    & /deep/ a {
      color: $color-active;
      text-decoration: underline;
      @include tap-event();
    }
  }
}
</style>
