<template>
  <div class="SnsShare">
    <div class="SnsShare__Text">
      <slot name="Title" />
    </div>
    <div class="SnsShare__buttons">
      <button class="SnsShare__button" @click="clickFacebookShare">
        <img src="@images/sns-share/icon_facebook.png" alt="facebook" />
      </button>
      <button class="SnsShare__button" @click="clickTwitterShare">
        <img src="@images/sns-share/icon_twitter.png" alt="twitter" />
      </button>
      <button v-if="locale === 'ja-jp'" class="SnsShare__button" @click="clickLineShare">
        <img src="@images/sns-share/icon_line.png" alt="line" />
      </button>
    </div>
  </div>
</template>

<script>
import LocaleMixin from '@/mixins/locale'
import ModelHero from '@/models/hero'

export default {
  name: 'SnsShare',
  mixins: [LocaleMixin],
  props: {
    hero: {
      type: Object,
      require: false,
      validator (val) {
        return val instanceof ModelHero
      }
    }
  },
  computed: {
    heroName () {
      return this.$t(`character.${this.hero.id}`)
    },
    shareText () {
      let text = this.$t('common.footer-share-top')
      switch (this.$route.name) {
        case 'Hero':
          text = this.$t('common.footer-share-cheer').replace(/({hero\.name}|{hero_name})/g, this.heroName)
          break
        case 'Random':
          text = this.$t('common.footer-share-cheer').replace(/({hero\.name}|{hero_name})/g, this.heroName)
          break
        case 'Voted':
          text = this.$t('common.footer-share-voted').replace(/({hero\.name}|{hero_name})/g, this.heroName)
          break
        case 'Result':
          text = this.$t('common.footer-share-result')
          break
        case 'ResultList':
          text = this.$t('common.footer-share-result')
      }
      return text
    },
    shareUrl () {
      let url = `${process.env.VUE_APP_URL}/${this.locale}?locale=${this.locale}`
      if (this.hero) {
        url = `${url}&hero_id=${this.hero.id}`
        switch (this.$route.name) {
          case 'Hero':
            url = `${url}&share=cheer`
            break
          case 'Random':
            url = `${url}&share=cheer`
            break
          case 'Voted':
            url = `${url}&share=vote`
            break
        }
      }
      return url
    },
    gaEventAction () {
      let action = 'share_snsplatform'
      switch (this.$route.name) {
        case 'Hero':
          action = 'share_snsplatform_cheer'
          break
        case 'Random':
          action = 'share_snsplatform_random'
          break
        case 'Voted':
          action = 'share_snsplatform_voted'
          break
        case 'Result':
          action = 'share_snsplatform_result'
          break
        case 'ResultList':
          action = 'share_snsplatform_result'
      }
      return action
    }
  },
  methods: {
    clickFacebookShare () {
      window.open(`http://www.facebook.com/share.php?u=${encodeURIComponent(this.shareUrl)}`, '_blank', 'noopener noreferrer')
    },
    clickTwitterShare () {
      const text = this.shareText.replace(/(<br>|{url})/g, '')
      window.open(`https://twitter.com/share?url=${encodeURIComponent(this.shareUrl)}&text=${encodeURIComponent(text)}`, '_blank', 'noopener noreferrer')
    },
    clickLineShare () {
      const text = this.shareText.replace(/<br>/g, '').replace(/{url}/g, this.shareUrl)
      window.open(`https://line.naver.jp/R/msg/text/?${encodeURIComponent(text)}`, '_blank', 'noopener noreferrer')
    }
  }
}
</script>

<style lang="scss" scoped>

.SnsShare {
  @at-root {
    & {
      border-top: 1px solid #19272b;
    }
    .SnsShare__Text {
      text-align: center;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .SnsShare__buttons {
      background: url(#{$img-path}sns-share/bg_share-buttons.png) repeat-x left center;
      height: 104px;
      display: flex;
      justify-content: center;
    }
    .SnsShare__button {
      background-color: transparent;
      padding: 0;
      margin: 0 25px;
      @include tap-event();
      img {
        width: 100%;
      }
    }
  }
}
</style>
