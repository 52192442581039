import Vue from 'vue'
import VueRouter from 'vue-router'
import { validateLocale } from '@/constants/locale'
import Store from '../store'
import { getServerParam as getServerParamLogin } from '@/constants/login'
import { getServerParam as getServerParamTerm } from '@/constants/term'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:lang',
    component: {
      template: '<router-view />'
    },
    beforeEnter: (to, from, next) => {
      if (validateLocale(to.params.lang)) {
        next()
      } else {
        next('/')
      }
    },
    children: [
      {
        path: '', // 1-1,2,3 トップページ
        name: 'Top',
        component: () => import(/* webpackChunkName: "top" */ '../views/top.vue')
      },
      {
        path: 'about', // 3-1,2 英雄総選挙とは
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
      },

      // NOTE: クローズ対応
      // {
      //   path: 'select-type', // 4-1 投票方法選択
      //   name: 'SelectType',
      //   meta: { requiresAuth: true, voteOpen: true },
      //   component: () => import(/* webpackChunkName: "select-type" */ '../views/select-type.vue')
      // },
      // {
      //   path: 'series', // 5-1 シリーズ選択ページ
      //   name: 'Series',
      //   meta: { requiresAuth: true, voteOpen: true },
      //   component: () => import(/* webpackChunkName: "series" */ '../views/series.vue'),
      //   beforeRouteEnter (to, from, next) {
      //     if (!to.query.series) {
      //       next({ path: to.path, query: { series: 101 } })
      //     } else {
      //       next()
      //     }
      //   }
      // },
      // {
      //   path: 'series/:seriesId', // 6-1 シリーズ英雄一覧ページ
      //   name: 'HeroList',
      //   meta: { requiresAuth: true, voteOpen: true },
      //   component: () => import(/* webpackChunkName: "heroes" */ '../views/hero-list.vue')
      // },
      // {
      //   path: 'series/:seriesId/:heroId', // 7-1 英雄投票ページ
      //   name: 'Hero',
      //   meta: { requiresAuth: true, voteOpen: true },
      //   component: () => import(/* webpackChunkName: "hero" */ '../views/hero.vue')
      // },
      // {
      //   path: 'series/:seriesId/:heroId/voted', // 9-1 投票完了ページ
      //   name: 'Voted',
      //   meta: { requiresAuth: true, voteOpen: true },
      //   component: () => import(/* webpackChunkName: "voted" */ '../views/voted.vue')
      // },
      // {
      //   path: 'random', // 11-1 ランダム投票ページ
      //   name: 'Random',
      //   meta: { requiresAuth: true, voteOpen: true },
      //   component: () => import(/* webpackChunkName: "random" */ '../views/random.vue')
      // },
      // {
      //   path: 'mypage', // 12-1 マイページ
      //   name: 'MyPage',
      //   meta: { requiresAuth: true },
      //   component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage.vue')
      // },

      {
        path: 'result', // 14-1,2 結果発表ページ
        name: 'Result',
        component: () => import(/* webpackChunkName: "results" */ '../views/result.vue')
      },
      {
        path: 'result/:page', // 15-1 結果発表ページ詳細
        name: 'ResultList',
        component: () => import(/* webpackChunkName: "results" */ '../views/result-list.vue')
      },
      {
        path: 'character', // 17-1 同一英雄ページ
        name: 'Character',
        component: () => import(/* webpackChunkName: "character" */ '../views/character.vue')
      },
      {
        path: 'debug', // デバッグページ
        name: 'Debug',
        component: () => import(/* webpackChunkName: "err" */ '../views/debug.vue')
      },
      {
        path: 'error', // 100 エラーページ loginエラー & その他エラー
        name: 'Error',
        component: () => import(/* webpackChunkName: "err" */ '../views/error.vue')
      },
      {
        path: '*', // 100 エラーページ 404
        name: 'Error404',
        component: () => import(/* webpackChunkName: "err" */ '../views/error.vue')
      }
    ]
  }
]

// NOTE: 本番環境の場合はdebugページのルーティングを削除
if (process.env.NODE_ENV === 'production' && !process.env.VUE_APP_LOCAL_SERVER) {
  routes[0].children = routes[0].children.filter(r => r.path !== 'debug')
}

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  // MEMO: NAログイン認証チェック
  if (to.matched.some(r => r.meta.requiresAuth)) {
    if (to.matched.some(r => r.meta.voteOpen)) {
      getServerParamTerm().isOpen && getServerParamLogin().isLogin ? next() : next({ name: 'Top', params: { lang: to.params.lang } })
    } else {
      getServerParamLogin().isLogin ? next() : next({ name: 'Top', params: { lang: to.params.lang } })
    }
  } else {
    next()
  }
})
router.beforeResolve((to, from, next) => {
  // MEMO: trailing slash対応
  if (/^.*\/$/g.test(to.path)) {
    next({ path: to.path.replace(/\/$/g, ''), replace: true, query: to.query })
  } else {
    next()
  }
})
router.afterEach((to, from) => {
  if (to.name !== from.name) {
    Store.dispatch('loader/start')
  }
})
export default router
