export default class Result {
  constructor (json) {
    try {
      this.map(json)
    } catch (error) {
      // eslint-disable-next-line
      console.warn('Invalid Type: ModelHistoru')
    }
  }

  map (json) {
    this.heroId = json.hero_id || ''
    this.rank = json.rank || ''
    this.seriesId = json.series_id || ''
    this.totalCount = +json.ttl_vote_count || 0
    this.count = +json.vote_count || 0
    this.sameHeroId = json.same_hero_id || ''
  }

  toJSON () {
    return {
      heroId: this.heroId,
      rank: this.rank,
      seriesId: this.seriesId,
      totalCount: this.totalCount,
      count: this.count,
      sameHeroId: this.sameHeroId
    }
  }
}
