<template>
  <p class="Trademark" v-html="$t('common.footer-003')" />
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
.Trademark {
  @at-root {
    & {
      padding: 30px 20px;
      word-break: break-word;
    }
  }
}
</style>
