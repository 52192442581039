class VisibilityChangeManager {
  constructor () {
    this._hidden = null
    this._visibilityChange = null
    this._handleChangeProxy = null
    this._functions = []
    this._functionsLen = 0

    this.setup()
  }

  setup () {
    if (typeof document.hidden !== 'undefined') {
      this._hidden = 'hidden'
      this._visibilityChange = 'visibilitychange'
    } else if (typeof document.msHidden !== 'undefined') {
      this._hidden = 'msHidden'
      this._visibilityChange = 'msvisibilitychange'
    } else if (typeof document.webkitHidden !== 'undefined') {
      this._hidden = 'webkitHidden'
      this._visibilityChange = 'webkitvisibilitychange'
    }

    this._handleChangeProxy = this.handleChange.bind(this)

    document.addEventListener(this._visibilityChange, this._handleChangeProxy, false)
  }

  handleChange () {
    this.call(document[this._hidden])
  }

  add (func) {
    this._functions.push(func)
    this._functionsLen = this._functions.length
  }

  call (hidden) {
    this._functions.forEach(func => func(hidden))
  }

  remove (func) {
    let f
    let i = 0
    const len = this._functions.length

    for (; i < len; i++) {
      f = this._functions[i]
      if (f === func) {
        this._functions.splice(i, 1)
        break
      }
    }

    this._functionsLen = this._functions.length
  }

  reset () {
    // this._hidden = null
    // this._visibilityChange = null
    // this._handleChangeProxy = null
    this._functions = []
    this._functionsOnVisibleLen = 0
  }

  destroy () {
    this.reset()
  }
}

export default new VisibilityChangeManager()
