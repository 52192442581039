<template>
  <nav class="MainMenu">
    <div class="MainMenu__Inner" ref="MainMenuInner">
      <ul class="MainMenu__Items">
        <li class="MainMenu__Item">
          <div class="MainMenu__ItemInner" @click="clickMenu('Top')">
            <span v-html="$t('menu.001')" />
          </div>
        </li>
        <li class="MainMenu__Item">
          <div class="MainMenu__ItemInner" @click="clickMenu('About')">
            <span v-html="$t('menu.002')" />
          </div>
        </li>
        <li v-if="resultOpen" class="MainMenu__Item">
          <div class="MainMenu__ItemInner" @click="clickMenu('Result')">
            <span v-html="$t('menu.003')" />
          </div>
        </li>
        <li class="MainMenu__Item">
          <div :class="{'-disabled': !voteOpen || isClose}" class="MainMenu__ItemInner" @click="clickMenu('Series')">
            <span v-html="$t('menu.004')" />
          </div>
        </li>
        <li class="MainMenu__Item">
          <div :class="{'-disabled': !voteOpen || isClose}" class="MainMenu__ItemInner" @click="clickMenu('Random')">
            <span v-html="$t('menu.005')" />
          </div>
        </li>
        <li class="MainMenu__Item">
          <div :class="{'-disabled': isClose}" class="MainMenu__ItemInner" @click="clickMenu('MyPage')">
            <span v-html="$t('menu.006')" />
          </div>
        </li>
        <li class="MainMenu__Item">
          <div class="MainMenu__ItemInner" @click="clickMenu('TermModal')">
            <span v-html="$t('menu.007')" />
          </div>
        </li>
        <li class="MainMenu__Item">
          <a
            href="https://fire-emblem-heroes.com/"
            target="_blank"
            rel="noopener noreferrer"
            class="MainMenu__ItemInner"
          >
            <span v-html="$t('menu.008')" />
          </a>
        </li>
        <li class="MainMenu__Item">
          <div class="MainMenu__ItemInner MainMenuLang -nohover">
            <span class="MainMenuLang__Label">{{ $t('menu.009') }} :</span>
            <language-select class="MainMenuLang__SelectForm" />
          </div>
        </li>
      </ul>
      <app-download class="MainMenu__AppDownload"/>
      <sns-account class="MainMenu__SnsAccount" />
      <trademark class="MainMenu__Trademark" />
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ModelModal from '@/models/modal'
import LocaleMixin from '@/mixins/locale'
import AppDownload from '@/components/global/app-download.vue'
import SnsAccount from '@/components/global/sns-account.vue'
import Trademark from '@/components/global/trademark.vue'
import LanguageSelect from '@/components/global/language-select.vue'
import NaLogin from '@/components/modals/na-login.vue'

export default {
  name: 'MainMenu',
  components: {
    AppDownload,
    SnsAccount,
    Trademark,
    LanguageSelect
  },
  mixins: [LocaleMixin],
  computed: {
    ...mapGetters('login', ['isLogin']),
    ...mapGetters('term', ['voteOpen', 'resultOpen', 'isClose'])
  },
  watch: {
    locale () {
      this.$nextTick(() => {
        const menuHeight = this.$refs.MainMenuInner.getBoundingClientRect().height
        this.setHeight(menuHeight + 214)
      })
    }
  },
  methods: {
    ...mapActions('modal', { showModal: 'show', showTerms: 'toggleTerms' }),
    ...mapActions('menu', { closeMenu: 'close', setHeight: 'updateHeight' }),
    clickMenu (page) {
      if (!page || this.$route.name === page) {
        this.closeMenu()
        return
      }
      switch (page) {
        case 'Top':
          // MEMO:
          // trailing slash対応 nameで指定するとなぜかスラッシュありになってエラー(vue router Error: Redirected)がでてしまうため
          this.$router.push({ path: `/${this.locale}` })
          break
        case 'MyPage':
          if (this.isLogin) {
            this.closeMenu()
            this.$router.push({ name: page, params: { lang: this.locale } })
          } else {
            this.showModal(new ModelModal({
              component: NaLogin,
              props: {
                toPath: 'mypage'
              }
            }))
          }
          break
        case 'Series':
          if (this.isLogin) {
            this.closeMenu()
            this.$router.push({ name: page, params: { lang: this.locale }, query: { series: 101 } })
          } else {
            this.showModal(new ModelModal({
              component: NaLogin,
              props: {
                toPath: 'series'
              }
            }))
          }
          break
        case 'Random':
          if (this.isLogin) {
            this.closeMenu()
            this.$router.push({ name: page, params: { lang: this.locale } })
          } else {
            this.showModal(new ModelModal({
              component: NaLogin,
              props: {
                toPath: 'random'
              }
            }))
          }
          break
        case 'TermModal':
          this.showTerms()
          break
        default:
          this.closeMenu()
          this.$router.push({ name: page, params: { lang: this.locale } })
          break
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const menuHeight = this.$refs.MainMenuInner.getBoundingClientRect().height
      this.setHeight(menuHeight + 214)
    })
  }
}
</script>
<style lang="scss" scoped>
.MainMenu {
  @at-root {
    & {
      width: $base-w;
      padding-top: 214px;
      padding-bottom: 30px;
      background: url(#{$img-path}the-menu/bg_menu.png) no-repeat;
    }
    .MainMenu__Inner {
      padding-bottom: 40px;
    }
    .MainMenu__Items {
      background: url(#{$img-path}the-menu/bg_line.png) no-repeat left bottom;
    }
    .MainMenu__Item {
      background: url(#{$img-path}the-menu/bg_line.png) no-repeat;
      width: 100%;
      overflow: hidden;
    }
    .MainMenu__ItemInner {
      display: flex;
      align-items: center;
      padding: 30px 20px 18px;
      color: $color-sub;
      font-size: $fs-2xlarge;
      font-weight: $bold;
      &::before {
        content: '';
        display: block;
        width: 40px;
        height: 29px;
        margin-right: 6px;
        background: url(#{$img-path}the-menu/icon_arrow.png) no-repeat;
      }
      &:not(.-nohover) {
        @include tap-event();
      }
      &.-disabled {
        pointer-events: none;
        opacity: .4;
      }
    }

    // misc
    .MainMenu__AppDownload {
      margin-top: 40px;
    }
    .MainMenu__SnsAccount {
      margin-top: 30px;
    }
    .MainMenu__Trademark {
      color: $color-sub;
    }
  }
}

// lang selector
.MainMenuLang {
  @at-root {
    & {
      padding: 30px 20px;
      flex-wrap: wrap;
      &::before {
        content : none;
      }
    }
    .MainMenuLang__Label {
      margin-right: 28px;
    }
    // NOTE: override generic component
    .MainMenuLang__SelectForm {
      min-width: 430px;
    }
  }
}
</style>
