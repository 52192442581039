export default {
  namespaced: true,

  state: {
    bgmId: null,
    isPlaying: false,
    currentBgm: new Audio()
  },

  getters: {
    bgmId (state) {
      return state.bgmId
    },
    isPlaying (state) {
      return state.isPlaying
    },
    currentBgm (state) {
      return state.currentBgm
    }
  },

  mutations: {
    setBgmState (state, bgm) {
      if (bgm && bgm.bgmId) {
        state.bgmId = bgm.bgmId
      }
      state.isPlaying = bgm.isPlaying || false
    }
  },

  actions: {
    update ({ commit }, bgm) {
      commit('setBgmState', bgm)
    }
  }
}
