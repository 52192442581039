<template>
  <div class="Footer" v-if="showFooter">
    <app-download v-show="showAppAndShare" class="Footer__AppDownload" />
    <div class="Footer__Bg">
      <sns-share v-show="showAppAndShare" class="Footer__SnsShare">
        <template v-slot:Title>
          <img :src="require(`@images/sns-share/${locale}/text_share.png`)" :alt="$t('common.footer-001')" />
        </template>
      </sns-share>
      <trademark v-show="showAppAndShare" class="Footer__Trademark" />
      <footer class="Footer__Bottom">
        <a v-show="showTermsLink" class="Footer__LinkAbout" @click="showTerms" v-html="$t('common.footer-002')" />
        <p class="Footer__Copyright" v-html="$t('common.footer-004')" />
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LocaleMixin from '@/mixins/locale'
import AppDownload from '@/components/global/app-download.vue'
import SnsShare from '@/components/global/sns-share.vue'
import Trademark from '@/components/global/trademark.vue'

export default {
  name: 'MainFooter',
  mixins: [LocaleMixin],
  components: {
    AppDownload,
    SnsShare,
    Trademark
  },
  computed: {
    showAppAndShare () {
      const notShowViews = ['Series', 'HeroList', 'Hero', 'Voted', 'Random', 'MyPage', 'Error', 'Error404']
      return notShowViews.every(s => s !== this.$route.name)
    },
    showTermsLink () {
      const notShowViews = ['Series', 'HeroList', 'Hero', 'Voted', 'Random', 'MyPage']
      return notShowViews.every(s => s !== this.$route.name)
    },
    showFooter () {
      return this.$route.name !== 'HeroList'
    }
  },
  methods: {
    ...mapActions('modal', { showTerms: 'toggleTerms' })
  }
}
</script>

<style lang="scss" scoped>
.Footer {
  @at-root {
    & {
      position: relative;
      z-index: map-get($z-index, footer);
    }
    .Footer__Bg {
      background-color: rgba(0, 0, 0, .5);
    }
    .Footer__Bottom {
      background-image: url(#{$img-path}the-footer/bg_top.png), url(#{$img-path}the-footer/bg_bottom.png);
      background-repeat: no-repeat;
      background-position: 50% 0, 50% 100%;
      background-size: 100% auto, 100% auto;
      background-color: #0d151a;
      padding: 20px 0 25px;
      text-align: center;
    }
    .Footer__LinkAbout {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 5px auto 20px;
      cursor: pointer;

      &::before {
        content: '';
        display: inline-block;
        width: 40px;
        height: 28px;
        background-image: url(#{$img-path}the-footer/icon_arrow.png);
        background-size: contain;
        margin-right: 8px;
        transform: translateY(3px);
      }
    }
    .Footer__Copyright {
      font-size: $fs-middle;
      text-shadow: $ts-base;
    }
    .Footer__SnsShare {
      margin-top: 20px;
    }
  }
}
</style>
