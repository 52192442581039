<template>
  <div class="LanguageSelect">
    <select
      v-model="selectLang"
      class="LanguageSelect__Main"
      required
    >
      <option
        v-for="(item, i) in localeList"
        :key="i"
        :value="item.value.name"
        :selected="isSelected(item.value.name)"
      >
        {{ item.value.label }}
      </option>
    </select>
  </div>
</template>

<script>
import { locales } from '@/constants/locale'
import LocaleMixin from '@/mixins/locale'

export default {
  name: 'LanguageSelect',
  mixins: [LocaleMixin],
  data () {
    return {
      selectLang: null
    }
  },
  computed: {
    localeList () {
      return locales.enums.map(l => l)
    }
  },
  watch: {
    '$route.params.lang' (value) {
      this.selectLang = value
    },
    'selectLang' (newValue, oldValue) {
      if (!oldValue) return
      const params = this.$route.params
      params.lang = newValue
      this.$router.replace({ name: this.$route.name, params: params, query: this.$route.query }).catch(() => {})
    }
  },
  created () {
    this.selectLang = this.locale
  },
  methods: {
    isSelected (lang) {
      return lang === this.locale
    }
  }
}
</script>

<style lang="scss" scoped>
.LanguageSelect {
  @at-root {
    & {
    }
    .LanguageSelect__Main {
      appearance: none;
      outline: none;
      width: 100%;
      height: 52px;
      border: solid 2px #69512e;
      border-radius: 0;
      padding: 0 20px;
      background: #e0dab1 url(#{$img-path}language-select/bg_select.png) no-repeat right center;
      background-size: auto 100%;
      font-size: $fs-2xlarge;
      @include tap-event();

      &:focus {
        outline: none;
      }
      &::-ms-expand {
        display: none;
      }
    }
  }
}
</style>
