<template>
  <component
    :is="tag"
    :disabled="disabled"
    :class="classes"
    class="ButtonBase"
    @click="$emit('click')"
  >
    <span class="ButtonBase__Inner">
      <slot />
    </span>
  </component>
</template>
<script>
export default {
  name: 'ButtonBase',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'button'
    }
  },
  computed: {
    classes () {
      return {
        '-disabled': this.disabled
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ButtonBase {
  @at-root {
    & {
      @include tap-event();
      position: relative;
      display: flex;
    }
    &.-disabled,
    &:disabled {
      pointer-events: none;
    }
    &.-disabled > *,
    &:disabled > * {
      opacity: 0.5;
    }
    .ButtonBase__Inner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }
}
</style>
