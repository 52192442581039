<template>
  <div class="BoxBlue" :class="{'-half': isHalfWidth}">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    isHalfWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>>
<style lang="scss" scoped>
.BoxBlue {
  @at-root {
    & {
      width: 572px;
      padding: 40px 35px;
      border-style: solid;
      border-image: url(#{$img-path}box-blue/bg.png) 40 40 fill / auto auto stretch;
    }
    // NOTE 汎用性がないので別物にしてもいい
    &.-half {
      width: 340px;
      padding: 40px;
      border-style: solid;
      border-image: url(#{$img-path}box-blue/half_bg.png) 40 40 fill / auto auto stretch;
    }
  }
}
</style>
