<template>
  <div class="NaLogin">
    <img class="NaLogin__Logo" src="@images/icon/ico_logo.png" alt="" height="142" width="142">
    <p v-if="toMyPage" class="NaLogin__Title" v-html="$t('modal.connect-003')" />
    <p v-else class="NaLogin__Title" v-html="$t('modal.connect-001')" />
    <p class="NaLogin__Em" v-html="$t('modal.connect-005')" />
    <ul class="NaLogin__List">
      <li class="NaLogin__ListItem" v-html="$t('modal.connect-006')" />
      <li class="NaLogin__ListItem" v-html="$t('modal.connect-007')" />
      <li class="NaLogin__ListItem" v-html="$t('modal.connect-008')" />
    </ul>
    <a :href="`/login?redirect=${redirectPath}`">
      <button-a class="NaLogin__Button">
        <span v-if="toMyPage" v-html="$t('modal.connect-004')" />
        <span v-else v-html="$t('modal.connect-002')" />
      </button-a>
    </a>
    <a
      href="https://my.nintendo.com/getting_started"
      target="_blank"
      rel="noopener noreferrer"
      class="NaLogin__Link"
      v-html="$t('modal.connect-009')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocaleMixin from '@/mixins/locale'
import ButtonA from '@/components/global/button-a.vue'

export default {
  name: 'NaLogin',
  mixins: [LocaleMixin],
  components: {
    ButtonA
  },
  computed: {
    ...mapGetters('modal', ['modal']),
    toMyPage () {
      return this.modal.props.toPath === 'mypage'
    },
    redirectPath () {
      const host = !process.env.VUE_APP_LOCAL_SERVER ? process.env.VUE_APP_URL : location.origin
      let path = `/${this.locale}/${this.modal.props.toPath}`
      if (this.modal.props.toPath === 'series') {
        path = `${path}?series=101`
      }
      return this.modal.props.toPath ? `${host}${path}` : location.href
    }
  }
}
</script>

<style lang="scss" scoped>
.NaLogin {
  @at-root {
    & {
      padding: 60px 0 50px;
      text-align: center;
    }
    .NaLogin__Title,
    .NaLogin__Em {
      font-size: $fs-2xlarge;
      font-weight: $bold;
      text-shadow: $ts-base;
      margin: 16px auto;;
      display: block;
      width: 80%;
    }
    .NaLogin__Em {
      font-size: $fs-3large;
    }
    .NaLogin__List {
      width: 460px;
      margin: 0 auto 20px;
      text-align: left;
    }
    .NaLogin__ListItem {
      font-size: $fs-large;
      text-shadow: $ts-base;
      padding-left: 35px;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        background: url(#{$img-path}icon/ico_bullet.png) no-repeat 0 8px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
      }
    }
    .NaLogin__Button {
      width: 490px;
      font-size: $fs-3xlarge;
      text-shadow: $ts-base;
      margin: 50px auto 15px;
    }
    .NaLogin__Link {
      padding-left: 40px;
      position: relative;
      color: $color-base;
      text-shadow: $ts-base;
      &:before {
        content: '';
        display: inline-block;
        background: url(#{$img-path}icon/ico_arrow.png) no-repeat;
        background-size: 100%;
        position: absolute;
        top: 60%;
        left: 0;
        transform: translateY(-50%);// FIXME この組み方の必要性があるか確認
        width: 40px;
        height: 29px;
      }
    }
  }
}
</style>
