<template>
  <button class="Buttons__Sound" @click="toggleBgm">
    <img :src="btnSound" alt="Sound" />
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isIE } from '@/libraries/util'
import VisibilityChangeManager from '@/libraries/visibility-change-manager'
import LocaleMixin from '@/mixins/locale'

export default {
  name: 'ButtonSound',
  mixins: [LocaleMixin],
  computed: {
    ...mapGetters('bgm', ['isPlaying', 'currentBgm', 'bgmId']),
    btnSound () {
      return this.isPlaying ? require('@images/the-header/btn_sound-off.png') : require('@images/the-header/btn_sound-on.png')
    },
    nextBgmId () {
      let seriesId
      if (this.$route.name === 'Series') {
        seriesId = +this.$route.query.series || this.defaultSeriesId
      } else if (this.$route.params.seriesId) {
        seriesId = +this.$route.params.seriesId
      } else {
        seriesId = 113
      }
      return seriesId
    },
    audioSrc () {
      let path = null
      const exceptionSeries = [113, 115, 116]
      const bgmId = this.nextBgmId
      if (exceptionSeries.includes(bgmId)) {
        const locale = this.locale === 'ja-jp' || this.locale === 'zh-tw' ? 'jp' : 'en'
        path = require(`@/assets/sounds/bgm_${bgmId}_${locale}.m4a`)
      } else {
        path = require(`@/assets/sounds/bgm_${bgmId}.m4a`)
      }
      return path
    }
  },
  watch: {
    '$route' () {
      // 再生中でなければ無視
      if (!this.isPlaying) {
        return
      }
      // 再生可能ページ
      if (this.nextBgmId !== this.bgmId) {
        this.playBgm()
      }
    }
  },
  created () {
    VisibilityChangeManager.add(this.handleVisibilityChange)
  },

  destroyed () {
    VisibilityChangeManager.remove(this.handleVisibilityChange)
  },
  methods: {
    ...mapActions('bgm', { bgmUpdate: 'update' }),
    toggleBgm () {
      this.isPlaying ? this.stopBgm() : this.playBgm()
    },
    setupBgm () {
      this.currentBgm.removeEventListener('canplaythrough', this.handleCanPlayThrough)
      this.currentBgm.addEventListener('canplaythrough', this.handleCanPlayThrough)
      this.currentBgm.volume = 1
      this.currentBgm.loop = true
      this.currentBgm.preload = 'none'
      if (isIE) {
        this.currentBgm.type = 'audio/mpeg'// mpeg, mp4, x-m4a, x-mp4
      }
      this.currentBgm.src = this.audioSrc
    },
    playBgm () {
      this.setupBgm()
      this.bgmUpdate({ isPlaying: true, bgmId: this.nextBgmId })
      this.currentBgm.load()
    },
    stopBgm () {
      if (!this.currentBgm) {
        return
      }
      this.currentBgm.removeEventListener('canplaythrough', this.handleCanPlayThrough)
      this.currentBgm.pause()
      this.bgmUpdate({ isPlaying: false })
    },
    handleCanPlayThrough () {
      this.currentBgm.play()
    },
    handleVisibilityChange (hidden) {
      if (!this.isPlaying) {
        return
      }

      hidden ? this.currentBgm.pause() : this.currentBgm.play()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
