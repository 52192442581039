<template>
  <div
    v-if="isShow"
    class="Modal"
    @touchmove.prevent
    @touchend.prevent
  >
    <iscroll-view
      :options="iScrollView.options"
      :scrollerClass="{ scroller: true }"
      ref="iscroll"
      class="Modal__Wrapper"
    >
      <div class="Modal__BoxBlueWrapper">
        <box-blue class="Modal__BoxBlue">
          <button-close class="Modal__ButtonClose" @close="closeModal" />
          <div class="Modal__Container">
            <component :is="modal.component" />
          </div>
        </box-blue>
      </div>
    </iscroll-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ButtonClose from '@/components/modals/close.vue'
import BoxBlue from '@/components/global/box-blue.vue'

export default {
  name: 'Modal',
  components: {
    ButtonClose,
    BoxBlue
  },
  data () {
    return {
      $iscroll: null,
      iScrollView: {
        options: {
          scrollbars: true,
          fadeScrollbars: true,
          shrinkScrollbars: 'scale',
          mouseWheel: true,
          click: true
        }
      }
    }
  },
  computed: {
    ...mapGetters('modal', ['isShow', 'modal'])
  },
  watch: {
    '$route.path' () {
      if (this.isShow) {
        this.closeModal()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$iscroll = this.$refs.iscroll
        this.refreshIScroll()
      })
    })
  },
  methods: {
    ...mapActions('modal', ['hide', 'reset']),
    closeModal () {
      this.hide()
      this.reset()
    },
    refreshIScroll () {
      if (!this.$iscroll) {
        return
      }
      setTimeout(() => {
        this.$iscroll.refresh()
        this.$iscroll.scrollTo(0, 0, 0)
      }, 100)
    }
  }
}
</script>
<style lang="scss" scoped>
.Modal {
  @at-root {
    & {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: map-get($z-index, modal);
      backface-visibility: hidden;
      overflow: hidden;
      background-color: rgba($color-sub, 0.8);
    }
    .Modal__Wrapper {
      touch-action: none;
      width: 100%;
      height: 100%;
    }
    .Modal__Container {
      margin: -20px;
    }
    .Modal__BoxBlueWrapper {
      padding: 50px 0;
    }
    .Modal__BoxBlue {
      position: relative;
      margin: 0 auto;
    }
    .Modal__ButtonClose {
      position: absolute;
      top: -18px;
      right: -21px;
      z-index: 1;
    }
  }
}
</style>
