<template>
  <div class="Loader" />
</template>

<script>
export default {
  name: 'Loader'
}
</script>
<style lang="scss" scoped>
.Loader {
  @at-root {
    & {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: map-get($z-index, loader);
      background: url(#{$img-path}loader/loader-line.png) no-repeat center center;

      &::before {
        content: '';
        display: block;
        width: 140px;
        height: 140px;
        background: url(#{$img-path}loader/loader.png) no-repeat center center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        animation: 1.5s linear infinite rotation;
      }
    }
  }
}

@keyframes rotation {
  0% { transform: rotate(0); }
  100% { transform: rotate(360deg); }
}
</style>
