import { getServerParam } from '@/constants/vote'
import HistoryModel from '@/models/history'

export default {
  namespaced: true,
  state: {
    history: [],
    isVoted: false,
    wallpaper: null
  },
  getters: {
    getHistory (state) {
      return state.history
    },
    isVoted (state) {
      return state.isVoted
    },
    wallpaper (state) {
      return state.wallpaper
    }
  },
  mutations: {
    setHistory (state, items) {
      state.history = items.map(h => new HistoryModel(h))
    },
    setVoteState (state, voteState) {
      state.isVoted = voteState
    },
    setWallpaper (state, wallpaper) {
      state.wallpaper = wallpaper
    }
  },
  actions: {
    init ({ commit }) {
      const vote = getServerParam()
      if (vote.voteHistories.items && vote.voteHistories.items.length > 0) {
        commit('setHistory', vote.voteHistories.items)
      }
      commit('setVoteState', vote.isVoted)
      commit('setWallpaper', vote.wallpaper)
    },
    update ({ commit }, dataset) {
      if (dataset.is_voted) commit('setVoteState', dataset.is_voted)
      if (dataset.wallpaper) commit('setWallpaper', dataset.wallpaper)
      if (dataset.vote_histories) commit('setHistory', dataset.vote_histories)
    }
  }
}
