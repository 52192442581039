export default {
  methods: {
    replaceText (text, values) {
      let _text = text
      for (const i in values) {
        const reg = new RegExp(`{${i}}`, 'g')
        if (reg.test(_text)) {
          _text = _text.replace(reg, values[i])
        }
      }
      return _text
    }
  }
}
