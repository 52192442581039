<template>
  <button-base
    :tag="tag"
    :disabled="disabled"
    :class="classes"
    class="ButtonA"
    @click="$emit('click')"
  >
    <slot />
  </button-base>
</template>
<script>
import ButtonBase from '@/components/global/button-base.vue'

export default {
  name: 'ButtonA',
  components: {
    ButtonBase
  },
  props: {
    type: {
      type: String,
      default: 'blue'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'button'
    },
    size: {
      type: String,
      default: null
    }
  },
  computed: {
    classes () {
      return {
        '-halfHeight': this.size === 'small',
        [this.typeClass]: true
      }
    },
    typeClass () {
      return `-${this.type}`
    }
  }
}
</script>
<style lang="scss" scoped>
.ButtonA {
  @at-root {
    $_border-width: 70px;
    $_small-border-width: 38px;
    & {
      height: 110px;
      padding-top: 2px;
      padding-bottom: 8px;
      border-style: solid; // for android
      border-image-width: auto auto;
      border-image-slice: 0 70 fill;
      border-image-repeat: stretch;
      color: $color-base;
    }
    &.-blue {
      border-image-source: url(#{$img-path}button-blue/bg_btn.png);
    }
    &.-gray {
      border-image-source: url(#{$img-path}button-gray/bg_btn.png);
    }
    &.-halfHeight {
      height: 60px;
      border-image-width: 0 $_small-border-width;
    }
    /deep/ .ButtonBase__Inner {
    }
  }
}
</style>
