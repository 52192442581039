import { getServerParam } from '@/constants/result'
import ModelResult from '@/models/result'

export default {
  namespaced: true,

  state: {
    all: [],
    female: [],
    male: []
  },

  getters: {
    getResultAll (state) {
      return state.all
    },
    getResultFemale (state) {
      return state.female
    },
    getResultMale (state) {
      return state.male
    }
  },

  mutations: {
    setResult (state, result) {
      state.all = result.all.map(r => new ModelResult(r))
      state.female = result.female.map(r => new ModelResult(r))
      state.male = result.male.map(r => new ModelResult(r))
    }
  },

  actions: {
    init ({ commit }) {
      const result = getServerParam()
      commit('setResult', result)
    }
  }
}
