import Login from './login.json'
import Term from './term.json'
import Vote from './vote.json'
import RankingMale from './ranking_male.json'
import RankingFemale from './ranking_female.json'
import RankingAll from './ranking_all.json'

const Result = {
  result: {
    male: RankingMale,
    female: RankingFemale,
    all: RankingAll
  }
}

export function getDummyJson () {
  if (process.env.VUE_APP_LOCAL_SERVER !== 'webpack') return null
  return Object.assign({}, Login, Term, Vote, Result)
}
