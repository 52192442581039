import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { locales, getFallbackLocale } from '@/constants/locale'
import About from '@/locales/about.yml'
import Character from '@/locales/character.yml'
import Common from '@/locales/common.yml'
import Errors from '@/locales/error.yml'
import Menu from '@/locales/menu.yml'
import Modal from '@/locales/modal.yml'
import MyPage from '@/locales/mypage.yml'
import Result from '@/locales/result.yml'
import SameCharacters from '@/locales/samecharacters.yml'
import Series from '@/locales/series.yml'
import Top from '@/locales/top.yml'
import VoteAgreement from '@/locales/vote_agreement.yml'
import Vote from '@/locales/vote.yml'
import Vars from '@/locales/vars.yml'

const messages = {}
for (const item of locales.enums.map(l => l.value.name)) {
  messages[item] = {
    about: About[item],
    character: Character[item],
    common: Common[item],
    error: Errors[item],
    menu: Menu[item],
    modal: Modal[item],
    mypage: MyPage[item],
    result: Result[item],
    samecharacters: SameCharacters[item],
    series: Series[item],
    top: Top[item],
    voteAgreement: VoteAgreement[item],
    vote: Vote[item],
    vars: Vars[item]
  }
}

Vue.use(VueI18n)

if (process.env.VUE_APP_LOCAL_SERVER) {
  console.info(messages)
}

const i18n = new VueI18n({
  locale: getFallbackLocale().value.name,
  messages: messages
})

export default i18n
