<template>
  <button class="ButtonClose" @click.once="clickBtn">
    <img :src="require(`@images/modal/${locale}/btn_close.png`)" alt="">
  </button>
</template>

<script>
import LocaleMixin from '@/mixins/locale'

export default {
  mixins: [LocaleMixin],
  methods: {
    clickBtn () {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.ButtonClose {
  @at-root {
    & {
      @include tap-event();
      width: 108px;
      height: 112px;
    }
  }
}
</style>
